<template>
    <el-dialog title="Zile libere" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='16'>
                    <el-form-item label='Nume' prop="Nume" >
                        <el-input  class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>   
                <el-col :md='8'>
                    <el-form-item label='Data' prop="Data">
                        <el-date-picker
                        class='full-width'
                        v-model="selectedObject.Data"
                        :picker-options="{firstDayOfWeek: 1}"
                        type="date"
                        format="dd.MM.yyyy"
                        value-format='yyyy-MM-dd'
                        placeholder="Alege data" />
                    </el-form-item>
                </el-col>  

            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import moment from 'moment';

    export default {
        name: "Zile_libere_dialog",
        extends: BasePage,
        components: {
          
        },
        
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {Nume: '', Data: ''},    
                selectedObject:{},              
                Info:{ },
                                   
                rules: {
                    Nume:           [ { required: true, message: 'Campul este obligatoriu' } ],
                    Data:           [ { required: true, message: 'Campul este obligatoriu' } ]
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
                this.showPopup      = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("zile_libere/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;
                }
            },
            async get_info(){
                var response        = await this.post("zile_libere/get_info_for_dialog" );
            },
            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("zile_libere/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>